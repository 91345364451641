<template>
  <ion-button @click.prevent.stop="(e) => emits('click', e)" class="big-btn" @click="create" :style="{ width, height }">
    <div class="content d-flex align-items-center justify-content-center">
      <inline-svg class="mr-2" src="/icons/plus-circle.svg" /> {{ label }}
    </div>
  </ion-button>
</template>

<script lang="ts" setup>
const emits = defineEmits(['click']);

defineProps({
  label: {
    type: String,
  },
  width: {
    type: String,
  },
  height: {
    type: String,
  },
});
</script>
<style scoped lang="sass">
.big-btn
  width: 100%
  height: 54px
  max-height: unset !important
  border: 2px solid #244163
  border-radius: 16px
  align-items: center
  background: rgb(247,6,207)
  background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 20%, rgba(95, 169, 238, 0) 100%), #A83CFF
  --background: transparent
  --padding-start: 0
  --padding-end: 0
  --padding-top: 0
  --padding-bottom: 0
  overflow: hidden
  &:active, &:focus
    opacity: 0.7
  .content
    user-select: none !important
    border-top: 0 !important
    border-left: 0 !important
    border-right: 0 !important
    border-radius: 16px
    margin-top: -6px
    margin-left: 0px
    width: calc(100% + 10px)
    max-width: unset !important
    min-width: unset !important
    height: calc(100% - 3px)
    border: 2px solid rgba(250, 166, 203, 1)
    font-weight: bold
    font-size: 24px
    font-family: "Roboto Slab", serif
    padding: 5px 0 0 0 !important
    background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%), #A83CFF
</style>
